body,
html {
  background-color: #ccc;
}

.sessionStatus {
  padding-top: 0.5rem;
}

.OTSubscriberContainer{
  height: 100% !important;
  width: 100% !important;
}

.OT_subscriber {
  /*display: block;*/
  /*height: 100% !important;*/
  /*width: 100% !important;*/
}

.OT_publisher {
}

.error {
  color: red;
}
